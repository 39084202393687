import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { Stack } from 'react-bootstrap';
import numeral from 'numeral';
import styled from 'styled-components';

const ConfirmationModal = ({ show, handleClose, plan, handleSubmit }) => {
    return (
        <Modal show={show} onHide={handleClose}>
            {/* <Modal.Header>
                <Modal.Title style={{ fontFamily: 'poppins' }}>Premium Breakdown</Modal.Title>
            </Modal.Header> */}
            <Modal.Body style={{ padding: '3% 8%' }}>
                {/* <Stack
                    gap={2}
                    direction="horizontal"
                    style={{ justifyContent: 'space-between', fontFamily: 'poppins' }}
                >
                    <p>Basic Premium</p>
                    <p>{numeral(plan?.basePrem ?? 0).format('0,0.00')}</p>
                </Stack>
                <Stack
                    gap={2}
                    direction="horizontal"
                    style={{ justifyContent: 'space-between', fontFamily: 'poppins' }}
                >
                    <p>Documentary Stamp Tax</p>
                    <p>{numeral(plan?.dstAmt ?? 0).format('0,0.00')}</p>
                </Stack>
                <Stack
                    gap={2}
                    direction="horizontal"
                    style={{ justifyContent: 'space-between', fontFamily: 'poppins' }}
                >
                    <p>Value Added Tax</p>
                    <p>{numeral(plan?.vatAmt ?? 0).format('0,0.00')}</p>
                </Stack>
                <Stack
                    gap={2}
                    direction="horizontal"
                    style={{ justifyContent: 'space-between', fontFamily: 'poppins' }}
                >
                    <p>Local Gov't. Tax</p>
                    <p>{numeral(plan?.lgtAmt ?? 0).format('0,0.00')}</p>
                </Stack> */}
                <hr />
                <Stack
                    gap={2}
                    direction="horizontal"
                    className='pt-3'
                    style={{ justifyContent: 'space-between', fontFamily: 'poppins' }}
                >
                    <p><strong>Total Amount Due</strong></p>
                    <p>₱ {numeral(plan?.premAmt ?? 0).format('0,0.00')}</p>
                </Stack>
                <hr />
                <label className="form-check-label" style={{ width: '100%' }}>
                    <p
                        className="d-grid gap-2"
                        style={{ fontSize: '12px', fontWeight: 'bold', fontStyle: 'italic', margin: 0 }}
                    >
                        *For payment using BPI Debit Mastercard® make sure its e-commerce access is turned on. Open your
                        BPI mobile app &gt; More &gt; Card Control.
                    </p>
                </label>
            </Modal.Body>
            <Modal.Footer style={{ border: 'none' }}>
                <Button
                    variant="secondary"
                    onClick={handleClose}
                    style={{ border: 'none', fontFamily: 'poppins', padding: '10px 15px', marginRight: '10px' }}
                >
                    Cancel
                </Button>
                <Button
                    variant="primary"
                    onClick={handleSubmit}
                    style={{
                        background: '#f2d841',
                        color: 'black',
                        border: 'none',
                        fontFamily: 'poppins',
                        padding: '10px 15px'
                    }}
                >
                    Confirm
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ConfirmationModal;
