import React from 'react';
import styled from 'styled-components';
import Image from 'react-bootstrap/Image';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Carousel from 'react-bootstrap/Carousel';

const StyledContainer = styled(Container)`
    margin-top: -60px;
    color: white;
    padding: 2% 10% !important;

    @media (min-width: 1367px) {
        padding: auto !important;
    }
`;

const Title = styled.h1`
    color: #b30000;
    font-family: Poppins;
    font-size: 60px;
    font-weight: 700;
`;

const StyleSpan = styled.span`
    font-size: 50px;
    font-weight: 500;
`;

const StyledDescription = styled.p`
    color: black;
    max-width: 85%; /* Limit the width to make it more compressed */
    margin: 0 auto; /* Center the text */
    font-size: 16px; /* Adjust the font size if needed */

    @media (min-width: 300px) and (max-width: 767px) {
        max-width: 100%;
        margin: 0;
        padding: 0;
        text-align: justify;
    }
`;

const StyledText = styled.p`
    color: black;
    font-size: 16px;
    max-width: 100;
    margin: auto 0;
    font-weight: bold;

    @media (min-width: 300px) and (max-width: 767px) {
        display: block;
        text-align: left;
    }
`;

const StyledCarousel = styled(Carousel)`
    &&& {
        width: 85%; /* Adjust the width to make the carousel smaller */
        height: 9.375rem;
        margin: 0 auto;
        border-radius: 16px; /* Add border radius to the carousel */
        overflow: hidden; /* Ensure the border radius is applied correctly */
        box-shadow: 0px 8px 14px 0px rgba(0, 0, 0, 0.2);
        background: white !important;

        .carousel-control-prev,
        .carousel-control-next {
            display: none !important;
        }

        .carousel-item {
            background-color: white;
        }

        .carousel-indicators {
            margin: 0;
        }

        .carousel-indicators button {
            background-color: #cc3137; /* Color of the indicators */
            border-radius: 50%; /* Make the indicators circular */
            width: 8px; /* Adjust the size */
            height: 8px; /* Adjust the size */
            margin-top: 8px;
        }

        @media (min-width: 300px) and (max-width: 767px) {
            width: 100%;
        }
    }
`;

const CarouselItemWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

const CarouselImage = styled.img`
    width: 100px;
    object-fit: cover;
    margin-top: 25px;

    @media (min-width: 300px) and (max-width: 767px) {
        display: none;
    }

    @media (min-width: 768px) and (max-width: 992px) {
        display: none;
    }
`;

const CarouselContent = styled.div`
    padding: 18px;
    @media (min-width: 300px) and (max-width: 767px) {
        margin: 0;
        padding: 0;
    }
`;

const CarouselTitle = styled.h3`
    color: black;
    font-family: Poppins;
    font-size: 20px;
    font-weight: 700;
    padding-top: 30px;
    margin-bottom: 0; /* Add bottom margin */

    @media (min-width: 300px) and (max-width: 767px) {
        font-size: 16px;
        text-align: center;
        padding-left: 6px;
        padding-right: 6px;
    }

    @media (min-width: 768px) and (max-width: 992px) {
        text-align: center;
    }
`;

const CarouselDescription = styled.p`
    color: #767778;
    font-family: Poppins;
    font-size: 16px;
    margin-top: 0;

    @media (min-width: 300px) and (max-width: 767px) {
        margin: auto;
        font-size: 12px;
        text-align: center;
        padding-left: 6px;
        padding-right: 6px;
    }
    
    @media (min-width: 768px) and (max-width: 992px) {
        text-align: center;
    }
`;

export default function Benefits() {
    return (
        <StyledContainer fluid>
            <Container>
                <Row fluid>
                    <Col>
                        <Row fluid>
                            <Col lg={12}>
                                <Title className="text-center mt-4">
                                    BPI <StyleSpan>Safe Sakay</StyleSpan>
                                </Title>
                            </Col>
                        </Row>
                        <Row xs={12} fluid>
                            <Container>
                                <Col>
                                    <StyledDescription className="mt-2">
                                        <strong>BPI Safe Sakay</strong> is an insurance plan powered by BPI MS, designed
                                        for modern-day heroes like you. As you brave the streets each day, pursuing your
                                        dreams and working towards a secure future for your loved ones, this plan offers
                                        the protection you need.
                                    </StyledDescription>
                                    <StyledDescription className="mt-2">
                                        <StyledText>The plan includes the following benefits:</StyledText>
                                    </StyledDescription>
                                </Col>
                            </Container>
                        </Row>
                        <Row className="mt-2">
                            <Col>
                                <StyledCarousel>
                                    <Carousel.Item>
                                        <CarouselItemWrapper>
                                            <CarouselImage src={require('../assets/images/banknotes.png')} />
                                            <CarouselContent>
                                                <CarouselTitle>Cash benefits</CarouselTitle>
                                                <CarouselDescription>
                                                    in case of death or disablement due to accidents, whether on or off the job{' '}
                                                </CarouselDescription>
                                            </CarouselContent>
                                        </CarouselItemWrapper>
                                    </Carousel.Item>
                                    <Carousel.Item>
                                        <CarouselItemWrapper>
                                            <CarouselImage src={require('../assets/images/banknotes_2.png')} />
                                            <CarouselContent>
                                                <CarouselTitle>Double the cash benefits</CarouselTitle>
                                                <CarouselDescription>
                                                    in case an accident occurs while riding a public transportation within the Philippines{' '}
                                                </CarouselDescription>
                                            </CarouselContent>
                                        </CarouselItemWrapper>
                                    </Carousel.Item>
                                    <Carousel.Item>
                                        <CarouselItemWrapper>
                                            <CarouselImage
                                                style={{ width: '70px' }}
                                                src={require('../assets/images/motorcycle.png')}
                                            />
                                            <CarouselContent>
                                                <CarouselTitle>
                                                    Coverage for motorcycling related accidents
                                                </CarouselTitle>
                                                <CarouselDescription>
                                                    usually excluded from most accident insurance plans and
                                                    solutions
                                                </CarouselDescription>
                                            </CarouselContent>
                                        </CarouselItemWrapper>
                                    </Carousel.Item>
                                    <Carousel.Item>
                                        <CarouselItemWrapper>
                                            <CarouselImage
                                                src={require('../assets/images/calendar.png')}
                                                style={{ width: '85px' }}
                                            />
                                            <CarouselContent>
                                                <CarouselTitle>24/7 coverage, 365 days a year —</CarouselTitle>
                                                <CarouselDescription>
                                                    providing you with constant peace of mind
                                                </CarouselDescription>
                                            </CarouselContent>
                                        </CarouselItemWrapper>
                                    </Carousel.Item>
                                    <Carousel.Item>
                                        <CarouselItemWrapper>
                                            <CarouselImage
                                                src={require('../assets/images/earth.png')}
                                                style={{ width: '100px' }}
                                            />
                                            <CarouselContent>
                                                <CarouselTitle>Worldwide Coverage, On and Off the Job</CarouselTitle>
                                                <CarouselDescription>
                                                    Enjoy protection wherever you may be
                                                </CarouselDescription>
                                            </CarouselContent>
                                        </CarouselItemWrapper>
                                    </Carousel.Item>
                                </StyledCarousel>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </StyledContainer>
    );
}
