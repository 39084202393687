import React from 'react';
import styled from 'styled-components';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Stack from 'react-bootstrap/Stack';
import Button from 'react-bootstrap/Button';

const StyledContainer = styled(Container)`
    &&& {
        background: #e9e5e2;
        z-index: 1;
    }
`;

const StyledStack = styled(Stack)`
    &&& {
        color: rgba(249, 249, 249, 1);
        font-family: 'Poppins';
        text-align: center;
        padding: 5% 0;
    }
`;

const StyledParagraph = styled.p`
    &&& {
        font-size: 16px;
        font-family: 'Poppins';
        margin: 0;
    }

    @media (min-width: 300px) and (max-width: 767px)  {
        padding-left: 4px;
        padding-right: 4px;
    }
`;

const StyledButton = styled.button`
    &&& {
        border-radius: 4px;
        border: 1px solid #B30000; 
        color: #B30000;
        background: white;
        z-index: 1;
        transition: all 0.2s ease-in 0s;
        padding: 4px 50px;
        max-width: fit-content;
        margin: 20px auto;
        font-size: 14px;
        
    &:hover {
            background: #B30000;
            color: white;
            border-color: #B30000;
        }
    }
`;

export default function Claim() {
    return (
        <StyledContainer fluid id="contact-us">
            <Row>
                <Col lg={12} className="justify-content-center text-center">
                    <StyledStack className="text-black">
                        <h3 style={{ fontSize: '36px' }}>File a Claim</h3>
                        <StyledParagraph>For inquiries, please call BPI MS Helpline at (+632) 8840-9000.</StyledParagraph>
                        <StyledParagraph>
                            To file a claim, please email{' '}
                            <a style={{ color: 'black' }} href="mailto:bpimsclaims@bpims.com">
                            bpimsclaims@bpims.com.
                            </a>
                        </StyledParagraph>
                        <StyledButton onClick={() => window.open('tel:+63288409000')}>CALL BPI MS HELPLINE</StyledButton>
                    </StyledStack>
                </Col>
            </Row>
        </StyledContainer>
    );
}
