import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Container, Spinner, Row, Col, Card, Stack } from 'react-bootstrap';

const StyledContainer = styled(Container)`
    padding: 0 17% !important;

    @media (min-width: 300px) and (max-width: 767px) {
        padding: 0 14% !important;
    }
`;

const StyledH1 = styled.h1`
    color: black;
    font-family: 'Poppins';
    font-size: 36px;
    font-weight: 700;
`;

const StyledPlanButton = styled.button`
    background: #cc3137;
    transition: all 0.2s ease-in 0s;
    padding: 5px 20px;
    border-width: 0;
    width: 80%;
    margin: 0 auto;
    color: white;
    font-weight: 500;

    &:hover {
        background: #b90000;
    }
`;

const StyledCol = styled(Col)`
    @media (min-width: 768px) and (max-width: 1024px) {
        margin-bottom: 10px;
    }

    @media (max-width: 767px) {
        margin-bottom: 10px;
    }
`;

const Ribbon = styled.div`
    font-weight: bold;
    font-family: 'Poppins';
    font-size: 12px;
    position: absolute;
    top: 12px;
    left: -45px;
    background-color: #75adab;
    color: white;
    padding: 5px 50px;
    transform: rotate(330deg);
    z-index: 1;
`;

const ConditionalStack = styled(Stack)`
    background-color: ${({ backgroundType }) => {
        if (backgroundType === 'first') return '#f59c00';
        if (backgroundType === 'popular') return '#e03138';
        if (backgroundType === 'third') return '#312682';
        return '#312682';
    }};
    padding: 20px;
`;

export default function Benefits({ handlePlanSelection, plans, loading }) {
    return (
        <StyledContainer fluid className="p-0" id="plans" style={{ minHeight: loading ? '460px' : 'unset' }}>
            <Row fluid className="mb-3">
                <Col>
                    <StyledH1>Choose your Plan:</StyledH1>
                </Col>
            </Row>
            <Row fluid className="justify-content-around">
                {loading && <Spinner animation="border" role="status" />}
                {plans.map((plan, index) => (
                    <PlanComponent
                        key={plan.id}
                        plan={plan}
                        handlePlanSelection={handlePlanSelection}
                        firstPlan={index === 0}
                        isPopular={index === 1}
                        thirdPlan={index === 2}
                    />
                ))}
            </Row>
        </StyledContainer>
    );
}

const PlanComponent = ({ plan, handlePlanSelection, firstPlan, isPopular, thirdPlan }) => {
    const handleSelectPlan = () => {
        handlePlanSelection(plan);
    };

    const backgroundType = firstPlan ? 'first' : isPopular ? 'popular' : thirdPlan ? 'third' : '';

    return (
        <StyledCol sm={12} md={12} lg={4}>
            <Card
                className="text-center"
                style={{ height: '100%', boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.2)', overflow: 'hidden' }}
            >
                {isPopular && <Ribbon>POPULAR!</Ribbon>}
                <Card.Body style={{ padding: 0 }} className="pb-4">
                    <Stack gap={3}>
                        <ConditionalStack backgroundType={backgroundType} style={{ padding: '20px' }}>
                            <p className="p-0 m-0 text-white" style={{ fontFamily: 'Poppins', fontSize: '16px' }}>
                                {plan.planName}
                            </p>
                            <Stack direction="horizontal" className="justify-content-center">
                                <p
                                    className="p-0 m-0 text-white"
                                    style={{ fontSize: '20px', fontFamily: 'Poppins', alignSelf: 'self-start' }}
                                >
                                    ₱
                                </p>
                                <p
                                    className="p-0 m-0 text-white"
                                    style={{ fontSize: '54px', fontWeight: 700, lineHeight: '54px' }}
                                >
                                    &nbsp;{plan.premAmt.toLocaleString()}
                                </p>
                                <p
                                    className="p-0 m-0 text-white"
                                    style={{
                                        fontFamily: 'Poppins',
                                        fontSize: '16px',
                                        color: '#fff',
                                        alignSelf: 'self-end'
                                    }}
                                >
                                    /Year
                                </p>
                            </Stack>
                        </ConditionalStack>
                        <Stack>
                            <Stack>
                                <p
                                    className="p-0 m-0"
                                    style={{
                                        color: '#536691',
                                        fontSize: '14px',
                                        fontWeight: 500,
                                    }}
                                >
                                    Up to
                                </p>
                            </Stack>
                            <Stack direction="horizontal" className="justify-content-center">
                                <p
                                    className="p-0 m-0"
                                    style={{
                                        color: 'rgb(111, 111, 111)',
                                        fontSize: '22px',
                                        fontWeight: 700,
                                        alignSelf: 'self-start'
                                    }}
                                >
                                    ₱
                                </p>
                                <p
                                    className="p-0 m-0"
                                    style={{
                                        color: 'rgb(111, 111, 111)',
                                        fontSize: '44px',
                                        fontWeight: 700,
                                        lineHeight: '44px'
                                    }}
                                >
                                    &nbsp;{(plan.covPerInc ?? 0).toLocaleString()}
                                </p>
                            </Stack>
                            <p className="mt-2"
                                style={{
                                    fontFamily: 'Poppins',
                                    fontSize: '12px',
                                    color: 'rgba(86, 86, 86, 1)',
                                    textAlign: 'center'
                                }}
                            >
                                Lump sum cash benefit for
                                <br />Accidental Death & Permanent Disablement
                            </p>
                        </Stack>
                        <Stack>
                            <Stack direction="horizontal" className="justify-content-center">
                                <p
                                    className="p-0 m-0"
                                    style={{
                                        color: 'rgb(111, 111, 111)',
                                        fontSize: '22px',
                                        fontWeight: 700,
                                        alignSelf: 'self-start'
                                    }}
                                >
                                    ₱
                                </p>
                                <p
                                    className="p-0 m-0"
                                    style={{
                                        color: 'rgb(111, 111, 111)',
                                        fontSize: '44px',
                                        fontWeight: 700,
                                        lineHeight: '44px'
                                    }}
                                >
                                    &nbsp;{(plan.maxCov ?? 0).toLocaleString()}
                                </p>
                            </Stack>
                            <p className="mt-2"
                                style={{
                                    fontFamily: 'Poppins',
                                    fontSize: '12px',
                                    color: 'rgba(86, 86, 86, 1)',
                                    textAlign: 'center'
                                }}
                            >
                                Double indemnity <br />
                                for accidental death while <br />
                                on public conveyance
                            </p>
                        </Stack>
                        <Stack>
                            <a href='https://www.bpi.com.ph/personal/insurance/bpi-safe-sakay' target="_blank" 
                                style={{
                                    fontFamily: 'Poppins',
                                    fontSize: '12px',
                                    color: '#cc3137',
                                    textAlign: 'center'
                                }}>
                                Learn more
                            </a>
                        </Stack>
                        <StyledPlanButton onClick={handleSelectPlan}>CHOOSE</StyledPlanButton>
                    </Stack>
                </Card.Body>
            </Card>
        </StyledCol>
    );
};
