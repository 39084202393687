import React from 'react';
import styled from 'styled-components';
import Container from 'react-bootstrap/Container';
import Image from 'react-bootstrap/Image';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import bannerDefault from '../assets/images/banner_default2.png';

const LandingContainer = styled(Container)`
    background: url(${bannerDefault}) no-repeat center center;
    background-size: cover;
    border-bottom-right-radius: 50px;
    margin-top: -80px;
    max-width: 100%;
    max-height: 100%;
    overflow: hidden;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 93%);

    @media (min-width: 300px) and (max-width: 500px)  {
        margin-top: 0;
        height: 95vh;
    }

    @media (min-width: 500px) and (max-width: 767px) {
        margin-top: 0;
        height: 110vh;
    }

    @media (max-height: 300px) and (min-width: 500px) and (max-width: 700px) {
       height: 200vh;
    }

    @media (min-width: 768px) and (max-width: 992px) {
        margin-top: 0;
        height: 150vh;
    }
`;

const Title = styled.h1`
    color: black;
    font-family: Poppins;
    font-size: 44px;
    font-weight: 700;
    position: sticky;
    padding-top: 130px;

    @media (min-width: 300px) and (max-width: 500px) {
        text-align: center;
        padding-top: 0;
    }

    @media (min-width: 500px) and (max-width: 767px) {
        padding-top: 6px;
        text-align: center;
    }

    @media (min-width: 768px) and (max-width: 992px) {
        padding-top: 0;
        text-align: center;
    }
`;

const Description = styled.p`
    color: black;
    font-size: 18px;
    font-family: Poppins;
    padding-right: 100px;
    position: sticky;

    @media (min-width: 300px) and (max-width: 500px) {
        text-align: center;
        padding-right: 0;
    }

    @media (min-width: 500px) and (max-width: 767px) {
        text-align: center;
        padding-right: 0;
    }

     @media (min-width: 768px) and (max-width: 992px) {
        text-align: center;
        padding-right: 20px;
        padding-left: 20px;
    }
`;

const StyledImage = styled(Image)`
    position: relative;

    @media (min-width: 768px) and (max-width: 992px) {
        width: 70%
    }

    @media (min-width: 500px) and (max-width: 767px) {
        width: 60%;
    }
`;

const StyledColumn = styled(Col)`
    padding-top: 45px;

    @media (min-width: 300px) and (max-width: 500px) {
        padding-top: 0 !important; /* Reset padding-top on small screens */
    }

    @media (min-width: 500px) and (max-width: 767px) {
        padding-top: 0;
    }
    
    @media (min-width: 768px) and (max-width: 992px) {
        padding-top: 10px;
    }
`;

export default function Landing() {
    return (
        <LandingContainer fluid>
            <StyledColumn>
                <Container fluid className="p-0">
                    <Row fluid lg={2} xs={1} className="pt-5">
                        <Col className="" style={{ display: 'flex', justifyContent: 'center' }}>
                            <StyledImage src={require('../assets/images/portrait.png')} fluid />
                        </Col>
                        <StyledColumn>
                            <Container fluid>
                                <Row fluid>
                                    <Col xs={12}>
                                        <Title>Embrace the daily hustle</Title>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12}>
                                        <Description>
                                            Navigate through bright and promising days knowing that
                                            <strong> BPI Safe Sakay</strong> has you covered from accidents, everyday
                                            and wherever you may be.
                                        </Description>
                                    </Col>
                                </Row>
                            </Container>
                        </StyledColumn>
                    </Row>
                </Container>
            </StyledColumn>
        </LandingContainer>
    );
}
